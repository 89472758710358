import React from 'react'

// import samet from '../../content/images/samet.jpg'

export default function Blurb({ title, children }) {
  return (
    <section className="blurb">
      <div className="container">
        <div>
          <h1>{title}</h1>
          {children}
        </div>
        {/* <div>
          <img src={samet} alt="Samet" className="avatar" />
        </div> */}
      </div>
    </section>
  )
}
